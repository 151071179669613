import { Problem, ProblemResponse, Progress } from "../types";

const PROBLEMS_ENDPOINT = "/problems";

export const fetchProblems = async (
  page: number,
  difficulty: string,
  tag: string,
  sort: string
): Promise<ProblemResponse> => {
  try {
    const [difficulty_from, difficulty_to] = getDifficultyRange(difficulty);

    let url = `${process.env.REACT_APP_API_URL}${PROBLEMS_ENDPOINT}?page=${page}`;

    if (difficulty_from !== 0) {
      url += `&difficulty_from=${difficulty_from}`;
    }
    if (difficulty_to !== 0) {
      url += `&difficulty_to=${difficulty_to}`;
    }
    if (tag !== "") {
      url += `&tag=${tag}`;
    }

    url += `&sort=${sort}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch problems");
    }

    const problemresponse: ProblemResponse = await response.json();

    return problemresponse;
  } catch (error) {
    console.error("Error fetching problems:", error);
    throw error;
  }
};
export const fetchUserProgress = async (
  username: string,
  difficulty: string,
  oncontest: boolean
): Promise<Progress[]> => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/user/`;

    if (oncontest) {
      url += `accuracy`;
    } else {
      url += `progress`;
    }

    url += `/${username}`;

    const [difficulty_from, difficulty_to] = getDifficultyRange(difficulty);

    if (difficulty_from !== 0) {
      url += `?difficulty_from=${difficulty_from}`;
    }
    if (difficulty_to !== 0) {
      url += `&difficulty_to=${difficulty_to}`;
    }

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch user progress");
    }

    const progress: Progress[] = await response.json();
    return progress;
  } catch (error) {
    console.error("Error fetching user progress:", error);
    throw error;
  }
};

function getDifficultyRange(difficulty: string): [number, number] {
  let difficulty_from: number;
  let difficulty_to: number;

  difficulty_from = 0;
  difficulty_to = 0;

  if (difficulty === "gray") {
    difficulty_from = 0;
    difficulty_to = 399;
  } else if (difficulty === "brown") {
    difficulty_from = 400;
    difficulty_to = 799;
  } else if (difficulty === "green") {
    difficulty_from = 800;
    difficulty_to = 1199;
  } else if (difficulty === "cyan") {
    difficulty_from = 1200;
    difficulty_to = 1599;
  } else if (difficulty === "blue") {
    difficulty_from = 1600;
    difficulty_to = 1999;
  } else if (difficulty === "yellow") {
    difficulty_from = 2000;
    difficulty_to = 2399;
  } else if (difficulty === "orange") {
    difficulty_from = 2400;
    difficulty_to = 2799;
  } else if (difficulty === "red") {
    difficulty_from = 2800;
    difficulty_to = 0;
  } else if (difficulty === "") {
    difficulty_from = 0;
    difficulty_to = 0;
  }

  return [difficulty_from, difficulty_to];
}

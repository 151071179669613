import React, { useState, useEffect } from "react";
import { fetchProblems } from "../services/api";
import { getUserProgress } from "../services/userService";
import ProblemList from "../components/ProblemList";
import Pagination from "../components/Pagination";
import { Problem, Progress } from "../types";

const HomePage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [problems, setProblems] = useState<Problem[]>([]);
  const [difficulty, setDifficulty] = useState("");
  const [tag, setTag] = useState("");
  const [sort, setSort] = useState("order_by_time_desc");

  useEffect(() => {
    fetchProblems(page, difficulty, tag, sort)
      .then((data) => {
        setProblems(data.problems);
        setMaxPage(data.max_page);
      })
      .catch((error) => console.error("Error fetching problems:", error));
  }, [page, difficulty, tag, sort]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">Problems</h1>

      <div className="container mx-auto p-4 flex flex-col items-center">
        <div className="flex space-x-4 mb-4 items-center">
          {/* Difficulty Dropdown */}
          <div className="w-60">
            <label className="block text-sm font-medium text-gray-700">
              Difficulty
            </label>
            <select
              className="mt-1 p-1 border rounded-md w-full text-sm"
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <option value="">all</option>
              <option value="gray">Gray</option>
              <option value="green">Green</option>
              <option value="brown">Brown</option>
              <option value="cyan">Cyan</option>
              <option value="blue">Blue</option>
              <option value="yellow">Yellow</option>
              <option value="orange">Orange</option>
              <option value="red">Red</option>
            </select>
          </div>

          {/* Tag Dropdown */}
          <div className="w-60">
            <label className="block text-sm font-medium text-gray-700">
              Tag
            </label>
            <select
              className="mt-1 p-1 border rounded-md w-full text-sm"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            >
              <option value="">all</option>
              <option value="DFS">DFS</option>
              <option value="BFS">BFS</option>
              <option value="BIT">BIT</option>
            </select>
          </div>

          {/* Sort Dropdown */}
          <div className="w-60">
            <label className="block text-sm font-medium text-gray-700">
              Sort
            </label>
            <select
              className="mt-1 p-1 border rounded-md w-full text-sm"
              value={sort}
              onChange={(e) => setSort(e.target.value)}
            >
              <option value="order_by_time_desc">Newest</option>
              <option value="order_by_time_asc">Oldest</option>
              <option value="order_by_difficulty_asc">Difficulty Asc</option>
              <option value="order_by_difficulty_desc">Difficulty Desc</option>
            </select>
          </div>
        </div>
      </div>

      {/* Problem List Component */}
      <ProblemList problems={problems} />

      {/* Pagination Component */}
      <div className="flex justify-center mt-4">
        <Pagination
          currentPage={page}
          totalPages={maxPage} // Replace with the actual total number of pages
          onPageChange={(newPage) => setPage(newPage)}
        />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useState, useEffect } from "react";
import { getUserProgress } from "../services/userService";
import UserProgress from "../components/UserProgress";
import { Progress } from "../types";

const UserProgressPage: React.FC = () => {
  // ユーザーの進捗情報のためのstate
  const [progress, setProgress] = useState<Progress[]>([]);
  const [username, setUsername] = useState("PAKACHU");
  const [difficulty, setDifficulty] = useState("");
  const [oncontest, setOnContest] = useState(false);

  const handleSearch = () => {
    // Fetch user progress when the search button is clicked
    getUserProgress(username, difficulty, oncontest)
      .then((data) => setProgress(data))
      .catch((error) => console.error("Error fetching user progress:", error));
  };

  useEffect(() => {
    handleSearch();
  }, [username, difficulty, oncontest]);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-4">User Progress</h1>

      <div className="container mx-auto p-4 flex flex-col items-center">
        <div className="flex space-x-4 mb-4 items-center">
          {/* Difficulty Dropdown */}
          <div className="w-60">
            <label className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              className="mt-1 p-1 border rounded-md w-full"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="w-60">
            <label className="block text-sm font-medium text-gray-700">
              Difficulty
            </label>
            <select
              className="mt-1 p-1 border rounded-md w-full"
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <option value="">all</option>
              <option value="gray">Gray</option>
              <option value="green">Green</option>
              <option value="brown">Brown</option>
              <option value="cyan">Cyan</option>
              <option value="blue">Blue</option>
              <option value="yellow">Yellow</option>
              <option value="orange">Orange</option>
              <option value="red">Red</option>
            </select>
          </div>

          {/* On Contest Dropdown */}
          <div className="w-60">
            <label className="block text-sm font-medium text-gray-700">
              On Contest
            </label>
            <select
              className="mt-1 p-1 border rounded-md w-full"
              value={oncontest.toString()}
              onChange={(e) => setOnContest(e.target.value === "true")}
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>

          {/* Search Button */}
          <div className="w-30" style={{ marginTop: "24px" }}>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {/* User Progress Component */}
      <UserProgress userProgress={progress} />
    </div>
  );
};

export default UserProgressPage;

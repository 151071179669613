import React from "react";
import { Problem } from "../types";
import ProblemListItem from "./ProblemListItem"; // あなたの実際のファイルパスに合わせて変更

type ProblemListProps = {
  problems: Problem[];
};

const ProblemList: React.FC<ProblemListProps> = ({ problems }) => {
  if (!problems || problems.length === 0) {
    return <div>No problems found.</div>;
  }

  return (
    <ul>
      {problems.map((problem) => (
        <ProblemListItem key={problem.id} problem={problem} />
      ))}
    </ul>
  );
};

export default ProblemList;

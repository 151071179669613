import React, { useEffect } from "react";
import { Progress } from "../types";
import { Chart } from "react-google-charts";

type UserProgressProps = {
  userProgress: Progress[];
};

const UserProgress: React.FC<UserProgressProps> = ({ userProgress }) => {
  useEffect(() => {
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawCharts);
  }, [userProgress]);

  const drawCharts = () => {
    userProgress.forEach((progress) => {
      drawChart(progress);
    });
  };

  const drawChart = (progress: Progress) => {
    const container = document.getElementById(
      `chartContainer-${progress.hashtag}`
    );

    if (!container) {
      console.error(`Container not found for ${progress.hashtag}`);
      return;
    }

    const data = new google.visualization.DataTable();
    data.addColumn("string", "Status");
    data.addColumn("number", "Count");

    const total = progress.total;
    const solved = progress.solved;
    const unsolved = total - solved;

    data.addRow(["Solved", solved]);
    data.addRow(["Unsolved", unsolved]);

    const options = {
      title: `${progress.hashtag}`,
      legend: "none" as google.visualization.PieChartLegend,
      colors: ["#7DD3FC", "#C70039"],
    };

    const chart = new google.visualization.PieChart(container);
    chart.draw(data, options);
  };

  const renderCharts = () => {
    const rows: JSX.Element[] = [];
    let currentRow: JSX.Element[] = [];

    userProgress.forEach((progress, index) => {
      drawChart(progress);

      currentRow.push(
        <div key={progress.hashtag} className="flex-shrink-0 p-0">
          <div
            id={`chartContainer-${progress.hashtag}`}
            style={{ width: "100%", height: "120px" }}
          ></div>
        </div>
      );

      if ((index + 1) % 4 === 0 || index === userProgress.length - 1) {
        rows.push(
          <div key={`row-${index}`} className="flex">
            {currentRow}
          </div>
        );
        currentRow = [];
      }
    });

    return rows;
  };

  return <div>{renderCharts()}</div>;
};

export default UserProgress;

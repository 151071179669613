import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import ProblemPage from "./pages/ProblemPage";
import UserProgressPage from "./pages/UserProgressPage";

function Navigation() {
  const location = useLocation();

  return (
    <nav>
      <ul className="flex space-x-4">
        <li>
          <NavLink
            to="/"
            className="hover:underline"
            style={{
              fontWeight: location.pathname === "/" ? "bold" : "normal",
              borderBottom:
                location.pathname === "/" ? "2px solid #fff" : "none",
            }}
          >
            Problems
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/user-progress"
            className="hover:underline"
            style={{
              fontWeight:
                location.pathname === "/user-progress" ? "bold" : "normal",
              borderBottom:
                location.pathname === "/user-progress"
                  ? "2px solid #fff"
                  : "none",
            }}
          >
            UserProgress
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default function App() {
  return (
    <Router>
      <div>
        <header className="bg-slate-900 p-4 text-sky-300 border-t border-b border-t-transparent border-b-sky-300 flex justify-between items-center">
          <h1 className="text-2xl font-bold">AtcoderClassify</h1>
          <Navigation />
        </header>

        <Routes>
          <Route path="/" element={<ProblemPage />} />
          <Route path="/user-progress" element={<UserProgressPage />} />
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </div>
    </Router>
  );
}

import { fetchUserProgress } from "./api"; // api.tsと同じディレクトリにあると仮定

// ユーザーの進捗情報を取得するサービス関数
export const getUserProgress = async (
  username: string,
  difficulty: string,
  oncontest: boolean
) => {
  try {
    const userProgress = await fetchUserProgress(
      username,
      difficulty,
      oncontest
    );
    return userProgress;
  } catch (error) {
    console.error("Error in getUserProgress service:", error);
    throw error;
  }
};

import React from "react";
import { Problem } from "../types";

type ProblemListItemProps = {
  problem: Problem;
};

const ProblemListItem: React.FC<ProblemListItemProps> = ({ problem }) => {
  return (
    <li className="bg-white shadow-md rounded-md p-6 mb-4 max-w-[600px] mx-auto">
      <div>
        <h3 className="text-xl font-bold mb-2">
          <a href={problem.url} className="text-blue-500 hover:underline">
            {problem.title}
          </a>
        </h3>
        <p className="text-gray-600">{problem.contest_id}</p>
        <p className="text-gray-600">Difficulty: {problem.difficulty}</p>
        <div className="flex flex-wrap items-center gap-2">
          {problem.hashtags.map((tag) => (
            <span
              key={tag}
              className="border border-solid border-blue-500 rounded px-2 py-1 text-blue-500 text-xs inline-block"
            >
              #{tag}
            </span>
          ))}
        </div>
      </div>
    </li>
  );
};

export default ProblemListItem;
